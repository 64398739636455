@font-face {
  font-family: 'DIN Next LT Pro';
  font-weight: 100;
  src: url("/fonts/din/DINNextLTPro-UltraLight.woff") format('woff');
}
@font-face {
  font-family: 'DIN Next LT Pro';
  font-weight: 200;
  src: url("/fonts/din/DINNextLTPro-Light.woff") format('woff');
}
@font-face {
  font-family: 'DIN Next LT Pro';
  font-weight: 400;
  src: url("/fonts/din/DINNextLTPro-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Triplicate';
  font-weight: 400;
  src: url("/fonts/triplicate/triplicate_t3_regular.woff") format('woff');
}
@font-face {
  font-family: 'Triplicate';
  font-weight: 400;
  font-style: italic;
  src: url("/fonts/triplicate/triplicate_t3_italic.woff") format('woff');
}
@font-face {
  font-family: 'Triplicate';
  font-weight: 700;
  src: url("/fonts/triplicate/triplicate_t3_bold.woff") format('woff');
}
@font-face {
  font-family: 'Triplicate';
  font-weight: 700;
  font-style: italic;
  src: url("/fonts/triplicate/triplicate_t3_bold_italic.woff") format('woff');
}
@font-face {
  font-family: 'Triplicate Caps';
  font-weight: 400;
  src: url("/fonts/triplicate/triplicate_c3_regular.woff") format('woff');
}
@font-face {
  font-family: 'Triplicate Caps';
  font-weight: 700;
  font-style: italic;
  src: url("/fonts/triplicate/triplicate_c3_italic.woff") format('woff');
}
* {
  box-sizing: border-box;
}
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'DIN Next LT Pro', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #222;
  background: #ede4d5;
}
html button,
body button,
html input,
body input {
  font-family: 'DIN Next LT Pro', 'Helvetica Neue', Helvetica, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
p:last-child {
  margin-bottom: 0;
}
.grid-container {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
}
.grid {
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.grid.grid--tight {
  grid-gap: 1rem;
}
a {
  opacity: 0.6;
  border-bottom: 1px solid #05e;
  text-decoration: none;
  color: #05e;
}
a:hover {
  opacity: 1;
}
.btn-text {
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  border-bottom: 1px solid #05e;
  cursor: pointer;
}
.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.page .header {
  flex: 0 1;
  padding: 2rem;
  background: #05e;
  color: #fff;
}
.page .header a,
.page .header button.btn-text {
  color: #fff;
  border-bottom: 1px dashed #fff;
}
.page .header .logo {
  display: inline-block;
  margin-bottom: 1rem;
  margin-right: 1rem;
  font-weight: bold;
  font-size: 1rem;
}
.page .header .omnibox {
  padding: 1rem 0;
}
.page .header .omnibox input {
  display: block;
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;
  padding: 1rem;
  font-size: 1.5rem;
}
.page .header .examples {
  margin: 0 auto;
  margin-top: 1rem;
  max-width: 20rem;
  text-align: center;
}
.page .header .examples .example {
  display: flex;
  margin-bottom: 0.1rem;
}
.page .header .examples .example .name {
  flex: 1;
  text-align: right;
  margin-right: 1rem;
}
.page .header .examples .example .control {
  flex: 1;
  text-align: left;
  margin-left: 1rem;
}
.page .results {
  flex: 1 0;
  padding: 2rem;
  background: #fff;
}
@media (max-width: 600px) {
  .page .results {
    padding: 2rem 1rem;
  }
}
@media (max-width: 600px) {
  .page .results .grid {
    display: block;
  }
}
.page .results .title {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 1.5rem;
}
.page .results .title .desc {
  flex: 1;
}
.page .results .title .type {
  flex: 1;
  text-align: right;
}
.page .results .title h2 {
  margin: 0;
  font-size: 2rem;
}
.page .results .title .label {
  display: inline-block;
  padding: 0.7rem 1rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  color: #fff;
  background: #05e;
}
@media (max-width: 600px) {
  .page .results .title {
    display: block;
  }
  .page .results .title .type {
    margin-bottom: 1rem;
    text-align: left;
  }
}
.page .results .info {
  font-size: 1.2rem;
}
.page .results .info .circle {
  max-width: 40rem;
  margin: 0 auto;
  text-align: center;
}
.page .results .info p.expl {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
.page .results .info h3 {
  margin: 0;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
}
.page .results .engraving-container {
  grid-row: 1;
  grid-column: 2;
  margin-bottom: 2rem;
}
.page .results .engraving-container .engraving {
  height: 6.5rem;
  overflow-x: auto;
  overflow-y: hidden;
}
.page .results .engraving-container .engraving svg {
  min-width: 500px;
}
.page .credit {
  flex: 0 1;
  padding: 1rem 2rem;
  background: #fff;
  text-align: right;
}
.page ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
}
.page ul.horizontal {
  display: flex;
  flex-wrap: wrap;
}
.page ul.horizontal li {
  flex: 1;
  margin-right: 0.5rem;
  text-align: center;
}
.page ul li {
  margin-bottom: 0.5rem;
  padding: 1rem;
  background: #f6f6f6;
}
@media (max-width: 600px) {
  .page ul li {
    padding: 0.5rem;
  }
}
